































































































import { defineComponent, watch } from "@vue/composition-api";
import { Pasien } from "../models/pasien";
import PasienFM from "../models/pasienForm";
import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "PasienModalForm",
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
  },
  props: { noRm: String },
  setup(props, { emit }) {
    const requiredFields = ["noRm", "nama", "gender", "tglLahir"];
    const nonRequiredFields = ["catatan"];
    const form = new PasienFM(requiredFields, nonRequiredFields);
    const composition = useFormModel(form);

    watch(
      () => props.noRm,
      (newValue) => {
        if (newValue) {
          const arr: Array<string> = newValue.split(" - ");
          if (arr.length > 0) {
            const val = arr[0].trim();
            if (val.length > 0) {
              const pasien = composition.instance as Pasien;
              pasien.noRm = val;
            }
          }
        }
      }
    );

    const saveForm = async () => {
      await composition.formRef.save(SaveContext.Create, false);
      const instance = composition.instance as Pasien;
      if (instance.id) {
        // TODO: Perlu dibuat konsisten, karena nama pasien
        // perlu ditambahkan noRM didepannya.
        const obj = {
          id: instance.id,
          nama: `${instance.noRm} - ${instance.nama}`,
        };
        emit("close", obj);
      }
    };

    return {
      // Composition
      ...composition,

      // Method
      saveForm,
    };
  },
});
